import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Carousel",
  code: "Carousel"
};
export const _frontmatter = {
  "menuLabel": "Carousel",
  "sortOrder": 3.3
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Carousel`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/iframe?args=&id=components-carousel--default-story" asEmbed={true} mdxType="LivePlayround" />
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Carousel } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`The Carousel component is a dynamic display element that allows users to view a set of images or content in a rotating manner, typically with navigation controls for manual browsing.`}</p>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="ready">
   The Carousel component provides a visually distinguishable display of multiple images or content items in a carousel format. It utilizes appropriate transitions and animations to ensure the content changes are easily noticeable by users. The carousel includes navigation buttons and indicators to improve the perceivability of the carousel's interactive nature.
      </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="ready">
    The Carousel component is operable through user interactions. Users can navigate through the carousel content using navigation buttons, indicators, or touch gestures (on touch-enabled devices). The component has clear focus indicators for keyboard users, enabling them to interact with the carousel using standard keyboard navigation.
      </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="ready">
    The purpose of the Carousel component is clear, presenting multiple images or content items in a slide-show-like manner. Users can easily comprehend the carousel's behavior and understand how to navigate through the content.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status="ready">
    The Carousel component is compatible with assistive technologies, ensuring that screen readers can convey the presence of a carousel and its interactive nature to visually impaired users.
  </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      